import { useAddProductsToCartAction } from "@graphcommerce/magento-product";
import { Trans } from "@lingui/react";
import IconCart3 from "../icons/IconCart3";
import Loading from "../icons/Loading";
import SelectField from "../widgets/Form/SelectField";
import { useLoading } from "../../context/loadingContext";
import { useEffect } from "react";

export default function AddToCart({ quantity, size, sku }: { quantity?: number, size?: 'small', sku: string }) {
  const action = useAddProductsToCartAction({ sku });
  const { setLoading } = useLoading();
  useEffect(()=>{
    if(action.loading){
      setLoading(true)
    }else{
      setLoading(false)
    }
  },[action.loading])

  return (
    <div className="flex items-center gap-2">
      {!quantity &&
        <>
          <span className="text-sm font-bold">
            <Trans id="Order number:" />
          </span>
          <SelectField defaultValue={1} className="max-w-[40px]" >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </SelectField>
        </>}
      <button {...action} {...{ sku }} className={`ordme_btn ordme_btn_success flex items-center ${size === 'small' ? '!min-h-7 !h-7 !font-normal' : ''} `}>
        {action.loading ?
          <span className="w-full text-center inline-block">

            <Loading color="#fff" width={14} height={14} className="text-white " />
          </span>
          : <>
            <IconCart3 width={size === 'small' ? 16 : 22} height={size === 'small' ? 16 : 22} className="ml-1" />

            <Trans id="Add to cart" />
          </>}
      </button>
    </div>
  )
}