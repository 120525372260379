import React, { SVGProps } from 'react'

const IconLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 5 100 40'
      width={props.width ?? 162}
      height={props.height ?? 69}
      {...props}
    >
      <polygon
        fill='#7c7979'
        points='53.9,35.3 57.7,8.6 62.3,8.6 67.2,29.1 78.4,8.6 83,8.6 79.2,35.3 75.4,35.3 78.1,17.1 68,35.3
64.7,35.3 60.3,17.1 57.5,35.3 53.9,35.3 '
      ></polygon>
      <path
        fill='#7c7979'
        d='M95.7,22.6c-0.7-5-6.9-5.5-9.3,0L95.7,22.6L95.7,22.6z M85.6,25.8c-0.5,6.4,5.4,6.6,12.6,4.4l-1,3.4
c-3.3,2.4-16.5,4.2-15.2-8.8c0.5-5.4,5.4-9.6,10.1-9.5c4.7,0.1,7.7,5.1,6.8,10.4L85.6,25.8L85.6,25.8z'
      ></path>
      <path
        fill='#b11e53'
        d='M27.7,35.3h-3.5l2.9-19.9h3.5l-0.5,3.6c1.5-2.7,4-3.8,7.6-3.6l-0.5,3.9c-4.5-1.1-7.5,2.6-8.2,6.8L27.7,35.3
L27.7,35.3z'
      ></path>
      <path
        fill='#b11e53'
        d='M39.6,25c-0.8,4,0.8,7.3,3.5,7.4c2.7,0.1,5.5-3.2,6.3-7.2c0.8-4-0.8-7.3-3.5-7.4C43.2,17.8,40.4,21,39.6,25
L39.6,25z M35.6,24.9c0.9-5.5,5.2-9.8,9.7-9.5c2.2,0.1,4.1,1.3,5.3,3.1L52,8.6h3.7L52,35.3h-3.7l0.4-2.9c-1.8,1.8-4.2,3-6.5,2.9
C37.6,35.1,34.7,30.4,35.6,24.9L35.6,24.9z'
      ></path>
      <path
        fill='#b11e53'
        d='M13,12.5c-0.1,0.1-0.2,0.1-0.2,0.1c-3.4,0.9-6.3,4.1-7.3,8.2c-1.2,5.2,1.4,9.8,5.8,10.3
c4.4,0.5,8.5-2.6,9.9-8.2c0.6-2.3,0.6-6-0.5-8.2c-1.1-2.2-3.7-3.5-5.1,0c-0.8,2.2-0.5,4.4-0.6,6.3c-0.1,3-3.2,2.4-2.8-0.5
c1.3-8.4,5.1-12,8.2-10.7c4.2,1.7,5.6,7.7,4.3,13.3c-1.7,7.4-8.2,12.8-14.5,12.1c-6.3-0.8-10-7.4-8.3-14.8
c1.3-5.7,5.4-10.2,10.1-11.6c0,0,0.1,0,0.1,0C14.8,8,15.9,10.1,13,12.5L13,12.5z'
      ></path>
      <g className='max-992:hidden'>
        <path fill='#7c7979' d='M49.4,42.1h-0.3v-4.6h2.5v0.3h-2.2v1.9h2.1v0.3h-2.1V42.1z'></path>
        <path
          fill='#7c7979'
          d='M53.6,42.2c-0.5,0-0.9-0.2-1.2-0.5s-0.4-0.7-0.4-1.3c0-0.5,0.1-1,0.4-1.3c0.3-0.3,0.6-0.5,1.1-0.5
c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.3,0.7,0.3,1.1v0.3h-2.4c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.9,0.4c0.2,0,0.4,0,0.5,0
c0.1,0,0.3-0.1,0.6-0.2V42c-0.2,0.1-0.4,0.1-0.5,0.2C54,42.2,53.8,42.2,53.6,42.2z M53.5,39c-0.3,0-0.6,0.1-0.8,0.3
c-0.2,0.2-0.3,0.5-0.3,0.9h2.1c0-0.4-0.1-0.7-0.3-0.9S53.8,39,53.5,39z'
        ></path>
        <path
          fill='#7c7979'
          d='M57.1,42.2c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.4-0.7-0.4-1.3c0-0.5,0.1-1,0.4-1.3c0.3-0.3,0.6-0.5,1.1-0.5
c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.3,0.7,0.3,1.1v0.3h-2.4c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.9,0.4c0.2,0,0.4,0,0.5,0
c0.1,0,0.3-0.1,0.6-0.2V42c-0.2,0.1-0.4,0.1-0.5,0.2C57.5,42.2,57.3,42.2,57.1,42.2z M57,39c-0.3,0-0.6,0.1-0.8,0.3
c-0.2,0.2-0.3,0.5-0.3,0.9H58c0-0.4-0.1-0.7-0.3-0.9C57.6,39.1,57.3,39,57,39z'
        ></path>
        <path fill='#7c7979' d='M59.6,42.1h-0.3v-4.9h0.3V42.1z'></path>
        <path
          fill='#7c7979'
          d='M64.4,42.1l-0.1-0.5h0c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.6-0.1-0.8-0.3
s-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.8c0.3-0.2,0.7-0.3,1.2-0.3l0.6,0v-0.2c0-0.3-0.1-0.6-0.2-0.7C64,39,63.8,39,63.5,39
c-0.3,0-0.6,0.1-1,0.3L62.5,39c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.9v2.3H64.4z M63.2,41.9
c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9v-0.3l-0.6,0c-0.5,0-0.8,0.1-1,0.2s-0.3,0.3-0.3,0.6c0,0.2,0.1,0.4,0.2,0.5
C62.8,41.9,63,41.9,63.2,41.9z'
        ></path>
        <path
          fill='#7c7979'
          d='M68.9,38.7c0.5,0,0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.3c0,0.6-0.1,1-0.4,1.3c-0.3,0.3-0.6,0.5-1.1,0.5
c-0.2,0-0.5,0-0.7-0.2c-0.2-0.1-0.3-0.2-0.5-0.4h0l-0.1,0.5h-0.2v-4.9h0.3v1.2c0,0.2,0,0.4,0,0.5l0,0.3h0c0.1-0.2,0.3-0.4,0.5-0.5
S68.6,38.7,68.9,38.7z M68.9,39c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.6-0.3,1.1v0.1c0,0.5,0.1,0.9,0.3,1.1
c0.2,0.2,0.5,0.3,0.8,0.3c0.4,0,0.7-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.3-1.1C70,39.5,69.6,39,68.9,39z'
        ></path>
        <path
          fill='#7c7979'
          d='M72.6,42.2c-0.5,0-0.9-0.2-1.2-0.5C71.2,41.4,71,41,71,40.5c0-0.5,0.1-1,0.4-1.3c0.3-0.3,0.6-0.5,1.1-0.5
c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.3,0.7,0.3,1.1v0.3h-2.4c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.9,0.4c0.2,0,0.4,0,0.5,0
c0.1,0,0.3-0.1,0.6-0.2V42c-0.2,0.1-0.4,0.1-0.5,0.2C73,42.2,72.8,42.2,72.6,42.2z M72.5,39c-0.3,0-0.6,0.1-0.8,0.3
c-0.2,0.2-0.3,0.5-0.3,0.9h2.1c0-0.4-0.1-0.7-0.3-0.9C73.1,39.1,72.8,39,72.5,39z'
        ></path>
        <path
          fill='#7c7979'
          d='M75.6,41.9c0.2,0,0.4,0,0.5-0.1v0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.7-0.2s-0.2-0.4-0.2-0.8V39h-0.5
v-0.2l0.5-0.1l0.2-0.8h0.2v0.8h1V39h-1v2.1c0,0.3,0,0.5,0.1,0.6S75.5,41.9,75.6,41.9z'
        ></path>
        <path
          fill='#7c7979'
          d='M77.8,41.9c0.2,0,0.4,0,0.5-0.1v0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.7-0.2s-0.2-0.4-0.2-0.8V39h-0.5
v-0.2l0.5-0.1l0.2-0.8h0.2v0.8h1V39h-1v2.1c0,0.3,0,0.5,0.1,0.6S77.6,41.9,77.8,41.9z'
        ></path>
        <path
          fill='#7c7979'
          d='M80.4,42.2c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.4-0.7-0.4-1.3c0-0.5,0.1-1,0.4-1.3c0.3-0.3,0.6-0.5,1.1-0.5
c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.3,0.7,0.3,1.1v0.3h-2.4c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.9,0.4c0.2,0,0.4,0,0.5,0
c0.1,0,0.3-0.1,0.6-0.2V42c-0.2,0.1-0.4,0.1-0.5,0.2C80.8,42.2,80.6,42.2,80.4,42.2z M80.3,39c-0.3,0-0.6,0.1-0.8,0.3
c-0.2,0.2-0.3,0.5-0.3,0.9h2.1c0-0.4-0.1-0.7-0.3-0.9C80.9,39.1,80.6,39,80.3,39z'
        ></path>
        <path
          fill='#7c7979'
          d='M83.9,38.7c0.1,0,0.3,0,0.5,0L84.3,39C84.1,39,84,39,83.8,39c-0.3,0-0.5,0.1-0.7,0.4c-0.2,0.2-0.3,0.6-0.3,0.9
v1.9h-0.3v-3.4h0.3l0,0.6h0c0.1-0.3,0.3-0.4,0.4-0.5C83.5,38.7,83.7,38.7,83.9,38.7z'
        ></path>
        <path fill='#7c7979' d='M87,42.1h-0.3v-4.9H87V42.1z'></path>
        <path
          fill='#7c7979'
          d='M88.1,37.8c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
c0,0.1-0.1,0.1-0.2,0.1C88.2,38.1,88.1,38,88.1,37.8z M88.5,42.1h-0.3v-3.4h0.3V42.1z'
        ></path>
        <path
          fill='#7c7979'
          d='M90.9,39h-0.8v3.1h-0.3V39h-0.6v-0.2l0.6-0.1v-0.3c0-0.4,0.1-0.7,0.2-0.9s0.4-0.3,0.8-0.3c0.2,0,0.4,0,0.6,0.1
l-0.1,0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.4-0.2,0.7v0.3h0.8V39z'
        ></path>
        <path
          fill='#7c7979'
          d='M92.9,42.2c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.4-0.7-0.4-1.3c0-0.5,0.1-1,0.4-1.3c0.3-0.3,0.6-0.5,1.1-0.5
c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.3,0.7,0.3,1.1v0.3h-2.4c0,0.5,0.1,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.9,0.4c0.2,0,0.4,0,0.5,0
c0.1,0,0.3-0.1,0.6-0.2V42c-0.2,0.1-0.4,0.1-0.5,0.2C93.3,42.2,93.1,42.2,92.9,42.2z M92.8,39c-0.3,0-0.6,0.1-0.8,0.3
c-0.2,0.2-0.3,0.5-0.3,0.9h2.1c0-0.4-0.1-0.7-0.3-0.9C93.3,39.1,93.1,39,92.8,39z'
        ></path>
        <path
          fill='#7c7979'
          d='M95,41.9c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3C95,42.2,95,42.1,95,41.9z'
        ></path>
        <path
          fill='#7c7979'
          d='M96.5,41.9c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3C96.6,42.2,96.5,42.1,96.5,41.9z'
        ></path>
        <path
          fill='#7c7979'
          d='M98,41.9c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3C98.1,42.2,98,42.1,98,41.9z'
        ></path>
      </g>
    </svg>
  )
}

export default IconLogo
