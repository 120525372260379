import { useMutation } from "@graphcommerce/graphql";
import { RemoveProductsFromCompareListDocument, useCompareList, useCompareListUidCreate, useCompareSummary } from "@graphcommerce/magento-compare";
import { Trans } from "@lingui/react";
import Image from "next/image";
import Link from "next/link";
import { useLoading } from "../../context/loadingContext";
import { toLocalString } from "../../lib/toLocalString";
import IconCross from "../icons/IconCross";

export default function ProductCompareModal() {
  const compareList = useCompareList();
  const create = useCompareListUidCreate()
  const [remove] = useMutation(RemoveProductsFromCompareListDocument)

  const items = compareList.data?.compareList?.items;
  const { setLoading } = useLoading();
  const handleRemove = async (id: string) => {
    if (compareList.data) {
      setLoading(true);
      await remove({
        variables: {
          products: [id],
          uid: await create()
        }
      });
      setLoading(false)
    }
  }

  const handleEmpty = async () => {
    if (compareList.data && items) {
      setLoading(true);
      await remove({
        variables: {
          products: [...items?.map(i => String(i?.product.id))],
          uid: await create()
        }
      });

      setLoading(false)

    }
  }
  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        {items?.length ? items?.map((item) => (
          <div className="relative flex items-start justify-between border-b border-dashed border-light-200  p-3" key={item?.uid}>
            <Link href={`/p/${item?.product.url_key}`}>
              <Image width={65} height={65} src={item?.product.small_image?.url ?? '/noimage.jpg'} alt={item?.product.small_image?.label ?? ''} />
            </Link>
            <div className="flex flex-1 flex-col pr-4">
              <Link href={`/p/${item?.product.url_key}`} className="text-sm text-light-400 hover:text-primary-500 me-3">
                {item?.product.name}
              </Link>
              <div className="flex mt-4 gap-3">
                <p className="text-sm font-semibold text-secondary-300">{toLocalString(item?.product.price_range.minimum_price.final_price.value ?? 0)} تومان</p>
                {item?.product.price_range?.minimum_price?.discount ? <p className="text-sm line-through font-light text-default-900">{toLocalString(item?.product.price_range.minimum_price.regular_price.value ?? 0)} تومان</p> : null}
              </div>
            </div>

            <IconCross width={18} height={18}
              onClick={() => handleRemove(String(item?.product.id))}
              className="cursor-pointer text-light-400 absolute top-1 left-1" />
          </div>
        )) : <p className="text-sm text-default-100 text-center mt-3">
          <Trans id='No products have been selected for comparison.' />
        </p>}
      </div>

      <div className="flex justify-between px-4 mt-5">
        {items?.length ? <button
          onClick={handleEmpty}
          type="button"
          className="ordme_btn ordme_btn_ghost flex items-center justify-center">
          <IconCross width={18} height={18} className="ml-1" />
          حذف همه
        </button> : <div />}

        <Link href="/product_compare" className="ordme_btn ordme_btn_secondary">
          <Trans id="Comparison page" />
        </Link>
      </div>
    </div>
  )
}