const IconGift = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 512 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clip-path='url(#clip0_1004_2)'>
        <path
          d='M492.8 115.2H369.8C377.549 100.122 379.588 82.751 375.541 66.2883C371.493 49.8255 361.632 35.3803 347.775 25.6143C333.917 15.8484 316.997 11.4197 300.132 13.1445C283.267 14.8692 267.594 22.6312 256 35C244.413 22.6039 228.733 14.8178 211.854 13.0787C194.975 11.3396 178.037 15.7651 164.166 25.5381C150.295 35.3112 140.427 49.7721 136.384 66.2517C132.341 82.7313 134.396 100.117 142.17 115.2H19.2C16.6786 115.2 14.1819 115.697 11.8525 116.662C9.52303 117.626 7.40644 119.041 5.62355 120.824C3.84067 122.606 2.4264 124.723 1.46151 127.052C0.496623 129.382 0 131.879 0 134.4L0 211.2C0 216.292 2.02285 221.176 5.62355 224.776C9.22425 228.377 14.1078 230.4 19.2 230.4H224V115.2H204.8C198.471 115.2 192.284 113.323 187.022 109.807C181.759 106.291 177.658 101.293 175.236 95.4459C172.814 89.5986 172.18 83.1645 173.415 76.9571C174.65 70.7497 177.697 65.0479 182.173 60.5726C186.648 56.0973 192.35 53.0496 198.557 51.8149C204.765 50.5801 211.199 51.2139 217.046 53.6359C222.893 56.0579 227.891 60.1594 231.407 65.4218C234.923 70.6841 236.8 76.871 236.8 83.2V115.2H275.2V83.2C275.2 76.871 277.077 70.6841 280.593 65.4218C284.109 60.1594 289.107 56.0579 294.954 53.6359C300.801 51.2139 307.236 50.5801 313.443 51.8149C319.65 53.0496 325.352 56.0973 329.827 60.5726C334.303 65.0479 337.35 70.7497 338.585 76.9571C339.82 83.1645 339.186 89.5986 336.764 95.4459C334.342 101.293 330.241 106.291 324.978 109.807C319.716 113.323 313.529 115.2 307.2 115.2H288V230.4H492.8C497.892 230.4 502.776 228.377 506.376 224.776C509.977 221.176 512 216.292 512 211.2V134.4C512 131.879 511.503 129.382 510.538 127.052C509.574 124.723 508.159 122.606 506.376 120.824C504.594 119.041 502.477 117.626 500.148 116.662C497.818 115.697 495.321 115.2 492.8 115.2Z'
          fill='white'
        />
        <path
          d='M38.4 480C38.4 485.092 40.4228 489.976 44.0235 493.576C47.6242 497.177 52.5078 499.2 57.6 499.2H224V268.8H38.4V480Z'
          fill='white'
        />
        <path
          d='M288 499.2H454.4C459.492 499.2 464.376 497.177 467.976 493.576C471.577 489.976 473.6 485.092 473.6 480V268.8H288V499.2Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1004_2'>
          <rect width='512' height='512' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconGift
