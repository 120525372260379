import { i18n } from '@lingui/core'
import Image from 'next/image'
import Link from 'next/link'
import { storeLogoQuery } from '../../graphql/storeLogo.gql'
import IconLogo from '../icons/IconLogo'
import { cn } from '../../lib/utils'

// eslint-disable-next-line import/no-default-export

type HeaderLogoProps =
  | { white?: boolean; storeLogo?: never }
  | {
      storeLogo?: storeLogoQuery['storeLogo']
      white?: never
    }

export default function HeaderLogo({ white, storeLogo }: HeaderLogoProps) {
  return (
    <>
      <div className='flex ltr flex-row justify-start items-center h-8 992:h-[70px] w-[68px] 992:w-[162px]'>
        <Link href='/'>
          {storeLogo ? (
            <Image
              width={162}
              height={69}
              src={storeLogo as string}
              alt={i18n._(
                'Ordme specialized reference for cosmetics, health and wellness products.',
              )}
              className='object-contain'
              style={white?{filter:'grayscale(100%) brightness(400%)',WebkitFilter:'grayscale(100%) brightness(400%)'}:{}}
            />
          ) : (
            <>
              <IconLogo
                width={162}
                height={69}
                className={cn('max-992:hidden', white && '*:fill-[#fff]')}
              />
              <IconLogo
                width={68}
                height={34}
                className={cn('992:hidden', white && '*:fill-[#fff]')}
              />
            </>
          )}
        </Link>
      </div>
    </>
  )
}
