import { useEffect } from 'react'
import IconIconMenu from '../icons/IconIconMenu'
import ActionIcons from './ActionIcons'
import BottomNavbar from './BottomNavbar'
import HeaderLogo from './HeaderLogo'
import { storeLogoQuery } from '../../graphql/storeLogo.gql'
import Link from 'next/link'

export default function MobileNavbar({ storeLogo }: storeLogoQuery) {
  useEffect(() => {
    const shakeIcons = document.querySelectorAll('.shake-icon')

    const triggerShake = () => {
      shakeIcons.forEach((icon) => {
        icon.classList.add('animate-shake')
        setTimeout(() => {
          icon.classList.remove('animate-shake')
        }, 500)
      })
    }

    const intervalId = setInterval(() => {
      triggerShake()
    }, 20000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className='fixed top-0 right-0 w-screen h-[50px] shadow-md bg-white flex 992:hidden justify-between items-center px-2 box-border z-30 max-992:px-4'>
      <div className='flex !justify-between w-[720px] m-auto'>
        <div>
          <IconIconMenu
            width={38}
            height={38}
            className='text-default-800 cursor-pointer'
            id='openMobileMenu'
          />
          <div
            className='hidden opacity-0 opa fixed top-0 right-0 left-0 bottom-0 z-50 bg-[#01010180]'
            id='menuBackDrop'
          ></div>
        </div>
        <div className='flex justify-end items-center gap-2'>
          <div className='flex items-center justify-between gap-4'>
            <Link href='/products/q/sort/bestsellers/stock_status/1' className='shake-icon'>
              <IconBxsHot width={24} height={30} />
            </Link>
            <ActionIcons />
          </div>
          <HeaderLogo />
          <Scripts />
        </div>
      </div>
    </div>
  )
}

function Scripts() {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const openMobileMenu = document.querySelector('#openMobileMenu')!
      const menuBackDrop = document.querySelector('#menuBackDrop')!
      const MobileMenu = document.querySelector('#MobileMenu')!
      openMobileMenu?.addEventListener('click', () => {
        if (menuBackDrop && MobileMenu) {
          menuBackDrop.classList.add('!block')
          MobileMenu.classList.add('!block')
          MobileMenu.classList.add('!translate-x-[0%]')
          MobileMenu.classList.remove('!translate-x-[100%]')
          menuBackDrop.classList.add('!opacity-100')
        }
      })

      menuBackDrop?.addEventListener('click', function (e) {
        menuBackDrop.classList.remove('!block')
        MobileMenu.classList.remove('!block')
        menuBackDrop.classList.remove('!opacity-100')
        MobileMenu.classList.remove('!translate-x-[0%]')
        MobileMenu.classList.add('!translate-x-[100%]')
      })
    }
  }, [])

  return null
}

const IconBxsHot = (props) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 190.8 239'
    xmlSpace='preserve'
    {...props}
  >
    <style type='text/css'>
      {`
        .st0{fill:#ED2024;}
        .st1{fill:#FFFFFF;}
      `}
    </style>
    <path
      className='st0'
      d='M165.1,85.8C121.4,37.5,111.5,0,111.5,0s-7.6,3.4-16.1,11.1c-5.6,5.1-11.5,12-16.1,21.1
      c-5.5,10.9-6.8,23.2-6.7,32.7C72.7,74,66.2,82,57.2,83.8C50.9,85,44.4,83,39.9,78.5l-8.8-8.8c0,0-13.1,18-15.3,21.4
      C5.7,106.4-0.1,124.7,0,144.4C0.4,196.9,42.8,239,95.4,239c52.7,0,95.4-42.7,95.4-95.4C190.8,121.9,178.6,100.7,165.1,85.8z'
    />
    <path
      className='st1'
      d='M120.8,149c-10.5,0-18.4,7-18.4,19.3c0,12.4,7.9,19.3,18.4,19.3c10.5,0,18.4-6.9,18.4-19.3
      C139.2,156.1,131.2,149,120.8,149L120.8,149z M120.8,176.9c-2.2,0-4.4-2-4.4-8.6c0-6.6,2.2-8.6,4.4-8.6c2.2,0,4.4,2,4.4,8.6
      C125.1,175,122.9,176.9,120.8,176.9L120.8,176.9z M120.8,176.9'
    />
    <polygon
      className='st1'
      points='110.7,117.4 95.2,140 63.4,186.6 80.1,186.6 95.2,164.5 127.4,117.4 '
    />
    <path
      className='st1'
      d='M88.4,135.7c0-12.3-7.9-19.3-18.4-19.3c-10.5,0-18.4,7-18.4,19.3c0,12.4,7.9,19.3,18.4,19.3
      C80.5,155,88.4,148.1,88.4,135.7L88.4,135.7z M65.7,135.7c0-6.6,2.2-8.6,4.4-8.6c2.2,0,4.4,2,4.4,8.6s-2.2,8.6-4.4,8.6
      C67.9,144.3,65.7,142.3,65.7,135.7L65.7,135.7z M65.7,135.7'
    />
  </svg>
)
