import { Hidden } from '@mui/material'
// eslint-disable-next-line import/no-cycle
import CartIcon from './CartIcon'
import CompareIcon from './ComparIcon'
import ProfileIcon from './ProfileIcon'
import SearchIcon from './SearchIcon'
import WishListIcon from './WishListIcon'
import { useRouter } from 'next/router'

// eslint-disable-next-line import/no-default-export
export default function ActionIcons() {
  const { pathname } = useRouter()
  const isShowCart = pathname !== '/checkout/cart' && pathname !== '/checkout/onePage'
  const isShowWishList = pathname !== '/wishlist'
  const isShowCompareIcon = pathname !== '/product_compare'
  return (
    <div className='flex flex-row justify-between gap-4 992:gap-5 items-center'>
      <SearchIcon className={{ icon: 'p-1 fill-[#888]', wrapperIcon: 'h-fit' }} />
      {isShowCart ? <CartIcon /> : null}
      <div className='max-992:hidden 992:flex 992:gap-5'>
        {isShowWishList ? <WishListIcon /> : null}
        {isShowCompareIcon ? <CompareIcon /> : null}
      </div>
      <div className='hidden 992:block 1170:-mx-1 my-auto  border-dashed border-l-[1px] border-ordme-gray-400/70 h-6 ' />
      <ProfileIcon />
    </div>
  )
}
