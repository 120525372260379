import { useMutation } from '@graphcommerce/graphql'
import { removeProductsFromWishlistDocument } from '../../graphql/wishlist/removeProductsFromWishlist.gql'
import { useLoading } from '../../context/loadingContext'
import { useWishlist } from '../../context/wishlistContext'
import ClearIcon from '@mui/icons-material/Clear'
import { ReactNode } from 'react'

export default function RemoveFromWishlistBtn({
  item_id,
  children,
}: React.PropsWithChildren<{ item_id: string }>) {
  const [removeFromWishlist] = useMutation(removeProductsFromWishlistDocument)
  const { setLoading } = useLoading()
  const { handleRefetch } = useWishlist()
  const handleClick = () => {
    setLoading(true)
    removeFromWishlist({
      variables: {
        wishlistId: '0',
        wishlistItemsIds: [item_id],
      },
    })
      .then(({ data }) => {
        if (!data?.removeProductsFromWishlist?.user_errors.length) {
          handleRefetch()
        } else {
          alert(!data?.removeProductsFromWishlist?.user_errors[0]?.message)
        }
      })
      .catch((err) => alert(err.message))
      .finally(() => setLoading(false))
  }

  return (
    <span onClick={handleClick} className='text-light-400 cursor-pointer'>
      {children ? children : <ClearIcon className='text-lg text-ordme-gray-200' />}
    </span>
  )
}
