import { useMutation } from '@graphcommerce/graphql'
import { AddProductsToCartForm } from '@graphcommerce/magento-product'
import { Trans } from '@lingui/react'
import { Container } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { useContext } from 'react'
import { ModalContext } from '../../context/context'
import { useLoading } from '../../context/loadingContext'
import { useWishlist } from '../../context/wishlistContext'
import { removeProductsFromWishlistDocument } from '../../graphql/wishlist/removeProductsFromWishlist.gql'
import AddToCart from '../Product/AddToCart'
import RemoveFromWishlistBtn from '../Product/RemoveFromWishlistBtn'
import IconCross from '../icons/IconCross'
import IconFolder from '../icons/IconFolder'
import useCustomerData from '../../customHooks/useCustomerData'
import Loading from '../icons/Loading'

export default function WishlistPage() {
  const useModal = useContext(ModalContext)
  const [removeProductFromWishlist] = useMutation(removeProductsFromWishlistDocument)
  const { wishlistId, handleRefetch, totalCount, items, wishlistLoading } = useWishlist()
  const { customerIsLogin, loading } = useCustomerData()
  const loggedIn = customerIsLogin()

  const { setLoading } = useLoading()
  const handleEmpty = () => {
    setLoading(true)
    removeProductFromWishlist({
      variables: {
        wishlistItemsIds:
          items?.reduce<string[]>(
            (c, i) => [...c, ...(i?.products?.map((p) => p?.wishlist_item_id!) ?? [])],
            [],
          ) ?? [],
        wishlistId,
      },
    })
      .then(({ data }) => {
        if (data?.removeProductsFromWishlist?.user_errors.length) {
          alert(data?.removeProductsFromWishlist?.user_errors[0]?.message)
        } else {
          handleRefetch()
        }
      })
      .catch((err) => alert(err.message))
      .finally(() => setLoading(false))
  }

  const title = items?.find((item) => item?.name)?.name
  const products = items?.flatMap((item) => item?.products)

  const isLoadingWishList = wishlistLoading || loading

  const isUserLoggedAndHaveItems = loggedIn && products?.length
  return (
    <Container
      maxWidth='md'
      sx={{
        padding: '0px !important',
        flex: '1 1 0%',
      }}
    >
      {isLoadingWishList ? (
        <div className='size-full flex items-center justify-center'>
          <Loading width={46} height={46} />
        </div>
      ) : isUserLoggedAndHaveItems ? (
        <>
          <div>
            <div className='flex items-center p-2 bg-light-50 border-b border-solid border-light-150'>
              <IconFolder width={14} height={14} className='text-primary-500' />
              <span className='text-light-400 mr-2 text-sm font-light pl-5'>{title}</span>
            </div>
            {products.map((p) => (
              <div
                key={p?.sku}
                className='flex flex-row py-2 items-center border-b border-dashed border-light-150 relative'
              >
                <Image
                  src={p?.small_image?.url ?? '/noimage'}
                  alt={p?.small_image?.label ?? ''}
                  width={60}
                  height={60}
                />
                <div className='flex flex-1 flex-col items-start'>
                  <div className='w-full flex items-center pl-1 text-sm justify-between'>
                    <Link
                      href={`/p/${p?.url_key}`}
                      className='flex flex-row justify-between pl-4 hover:text-primary-500'
                    >
                      {p?.name}
                    </Link>
                    <span className='whitespace-nowrap'>
                      <RemoveFromWishlistBtn item_id={p?.wishlist_item_id} />
                    </span>
                  </div>
                  <div className='flex w-full flex-row items-center justify-between mt-2'>
                    {p?.stock_status === 'IN_STOCK' ? (
                      <div className='flex w-full justify-between items-center pl-2'>
                        <p className='text-secondary-500 text-sm font-semibold'>
                          {p.price_range?.minimum_price.final_price.value} تومان
                        </p>
                        {p.price_range?.minimum_price.discount?.amount_off > 0 && (
                          <p className='text-light-400 font-medium text-sm line-through pr-3'>
                            {p.price_range?.minimum_price.regular_price.value} تومان
                          </p>
                        )}
                        <AddProductsToCartForm>
                          <AddToCart quantity={1} size='small' sku={p.sku} />
                        </AddProductsToCartForm>
                      </div>
                    ) : (
                      <p className='text-light-400 text-sm'>
                        <Trans id='unavailable' />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='flex justify-between items-center px-2 mt-6'>
            <button
              onClick={handleEmpty}
              className='ordme_btn ordme_btn_ghost flex items-center'
            >
              <IconCross width={22} height={22} className='ml-0.5' />
              <Trans id='حذف همه' />
            </button>
            <Link href='/wishlist'>
              <button
                className='ordme_btn'
                onClick={useModal.close}
              >
                <Trans id='Management of interested parties' />
              </button>
            </Link>
          </div>
        </>
      ) : (
        <p className='mt-4 text-sm'>
          {loggedIn ? (
            <Trans id='No products have been added to the wishlist.' />
          ) : (
            <Trans id='To add a product to your wish list, please log in or register.' />
          )}
        </p>
      )}
    </Container>
  )
}
