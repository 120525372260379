import { useEffect, useRef, useState } from 'react'
import { useQuery } from '@graphcommerce/graphql'
import { getAdsBannerDocument } from '../../graphql/getAdsBanner.gql'
import Link from 'next/link'

export default function AdsBanner() {
  const { data, loading } = useQuery(getAdsBannerDocument)
  const [animating, setAnimating] = useState(false)
  const message = useRef('')

  const topNavStaticMessages = data?.topNavStaticMessages ?? []

  const [
    firstText,
    secondText,
    thirdText,
    linkHref,
    imageUrl = '',
    bgColorHex = '',
    textColorHex = '',
  ] = topNavStaticMessages

  const bannerTexts = [firstText, secondText, thirdText]
  const backgroundImage = `url(${imageUrl})`
  const backgroundColor = `#${bgColorHex}`
  const color = `#${textColorHex}`

  const getRandomText = () => {
    const availableBannerTexts = bannerTexts.filter((text) => text !== message.current)
    const selectedText =
      availableBannerTexts[Math.floor(Math.random() * availableBannerTexts.length)] ?? ''
    return selectedText
  }

  useEffect(() => {
    if (bannerTexts.length > 0) {
      const interval = setInterval(() => {
        setAnimating(true)
        setTimeout(() => {
          setAnimating(false)
          message.current = getRandomText()
        }, 300)
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [bannerTexts])

  return (
    <Link target='_blank' href={linkHref ?? '/'}>
      {loading ? (
        <div className='w-full h-11 992:h-10 translate-y-[50px] 992:translate-y-[0px] z-0 bg-light-100 animate-pulse' />
      ) : (
        <div
          style={{ backgroundImage, backgroundColor, backgroundSize: '100% 100%' }}
          className='w-full mt-0 flex justify-center items-center h-11 992:h-10 translate-y-[50px] 992:translate-y-[0px] z-0 992:border-x-0 max-992:m-0'
        >
          <div
            style={{ color }}
            className={`max-w-970 1170:max-w-1170 text-center text-sm font-semibold ${
              animating ? 'animate-fadeOut' : 'animate-fadeIn'
            }`}
          >
            {message.current}
          </div>
        </div>
      )}
    </Link>
  )
}
