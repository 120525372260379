import { useCustomerQuery } from '@graphcommerce/magento-customer'
import { Trans } from '@lingui/react'
import { Button } from '@mui/material'
import Link from 'next/link'
import { useContext } from 'react'
import { ModalContext } from '../../context/context'
import { customerDocument } from '../../graphql/login/currentCustomerData.gql'
import AuthForms from '../AuthForms'
import { SignOutForm } from '../AuthForms/SignOutForm'

export default function MobileLoginBtns() {
  const customer = useCustomerQuery(customerDocument, { fetchPolicy: 'network-only' })
  const useModal = useContext(ModalContext)

  const firstname = `${customer?.data?.customer?.firstname} ${customer?.data?.customer?.lastname}`

  return customer?.data?.customer ? (
    <div className='flex items-center justify-between gap-1 text-white'>
      <Button className='transition text-primary-500 hover:text-black no-underline text-base !px-0 !bg-transparent font-medium !max-w-fit !min-w-fit'>
        <Link href='/account' className='text-white transition no-underline max-md:text-base'>
          {firstname}
        </Link>
      </Button>
      <span className='text-default-600'> - </span>
    </div>
  ) : (
    <div className='flex items-center justify-center text-white'>
      <Link
        href='javascript:void(0)'
        className='text-sm no-underline closeMenu'
        onClick={() =>
          useModal.set &&
          useModal?.set({
            content: <AuthForms activeForm='login' />,
            open: true,
            title: '',
            position: 'center',
            width: 'max-sm:size-full md:size-fit size-fit p-0 rounded-none',
          })
        }
      >
        <Trans id='Log in' />
      </Link>
      <div className='w-[1px] h-3 bg-white mx-3' />
      <Link
        href='javascript:void(0)'
        onClick={() =>
          useModal.set &&
          useModal?.set({
            content: <AuthForms activeForm='signup' />,
            open: true,
            title: '',
            position: 'center',
            width: 'max-sm:size-full md:size-fit size-fit p-0 rounded-none',
          })
        }
        className='text-sm no-underline closeMenu'
      >
        <Trans id='Register' />
      </Link>
    </div>
  )
}
