import Link from 'next/link'
import SearchBox from '../SearchBox'
import { useEffect, useRef, useState } from 'react'
import IconZoom from '../../icons/IconZoom'
import { cn } from '../../../lib/utils'

type SearchIconProps = {
  className?: {
    wrapperIcon?: string
    icon?: string
  }
}

export default function SearchIcon({ className }: SearchIconProps) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const wrapperRef2 = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      wrapperRef2.current &&
      !wrapperRef2.current.contains(event.target)
    ) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  return (
    <>
      <div className={cn('relative 992:hidden h-4', className?.wrapperIcon)} ref={wrapperRef2}>
        <Link href={'javascript:void(0)'} onClick={() => setOpen(true)}>
          <IconZoom width={38} height={38} className={cn('fill-[#aaaaaa]', className?.icon)} />
        </Link>
      </div>

      {open && (
        <div className='fixed  justify-center top-0 left-0 right-0 bottom-0 bg-[#000000b3] z-[999999999999]'>
          <div className='m-auto mt-12 items-center w-auto' ref={wrapperRef}>
            <SearchBox open={open} setOpen={setOpen} />
          </div>
        </div>
      )}
    </>
  )
}
