import { WaitForQueries } from '@graphcommerce/ecommerce-ui'
import { PageOptions } from '@graphcommerce/framer-next-pages'
import { ApolloCartErrorAlert, useCartQuery } from '@graphcommerce/magento-cart'

import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { FullPageMessage, GetStaticProps } from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import ClearIcon from '@mui/icons-material/Clear'
import { CircularProgress } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { useContext } from 'react'
import { LayoutOverlayProps } from '..'
import { ModalContext } from '../../context/context'
import { getCartDocument, getCartQuery } from '../../graphql/checkout/getCart.gql'
import { graphqlSharedClient } from '../../lib/graphql/graphqlSsrClient'
import { Money } from '../Money'
import { RemoveItemFromCart } from '../removeItemFromCart/inedx'
import IconGift from '../icons/IconGift'

type Props = Record<string, unknown>
type GetPageStaticProps = GetStaticProps<LayoutOverlayProps, Props>

type cartItemType = NonNullable<NonNullable<getCartQuery['cart']>['items']>[number]
type freeGiftItem = NonNullable<cartItemType>['mp_free_gifts']

export const checkGiftIsAutoAdded = (props?: freeGiftItem): boolean => !!props?.is_free_gift
function CartPage() {
  const cart = useCartQuery(getCartDocument, { errorPolicy: 'all' })
  const { data, error } = cart
  const hasItems =
    (data?.cart?.items?.length ?? 0) > 0 &&
    typeof data?.cart?.prices?.grand_total?.value !== 'undefined'

  const useModal = useContext(ModalContext)

  const earnPoint = () => {
    if (cart.data?.cart?.prices?.mp_reward_segments)
      return cart.data?.cart?.prices?.mp_reward_segments?.filter(
        (item) => item?.code === 'mp_reward_earn',
      )[0]?.value
    return 0
  }

  const products = data?.cart?.items?.flatMap((item) => item)

  const productsElement = products?.map((item) => (
    <div key={item?.id} className='h-[100px] flex items-center border-dashed border-b pb-2'>
      <div className='w-[60px]'>
        {item?.product?.thumbnail && (
          <Image
            src={item?.product?.thumbnail?.url}
            alt={item?.product?.name}
            className='w-full h-fit'
            width={60}
            height={60}
          />
        )}
      </div>
      <div className='h-full flex flex-col justify-center py-1 flex-1 [&>*:not(:last-child)]:mb-2'>
        <Link
          href={`/p/${item?.product?.url_key}`}
          className='text-sm hover:text-primary-500 transition-colors'
        >
          {item?.product.name}
        </Link>
        {item?.__typename == 'ConfigurableCartItem' ? (
          <div className='text-ordme-gray-400 w-[90%] text-xs'>
            {item.configurable_options &&
              item.configurable_options.map((option) => (
                <>
                  <span>
                    {option?.option_label} :{option?.value_label}
                  </span>
                </>
              ))}
          </div>
        ) : null}

        <span className='text-sm font-medium text-secondary-400'>
          <Money value={item?.prices?.row_total?.value} />
        </span>
      </div>

      <div className='w-[60px] h-full relative flex items-center justify-center'>
        {checkGiftIsAutoAdded(item?.mp_free_gifts) ? (
          <div className='flex flex-col gap-1'>
            <span className='flex text-xs text-[#666]'>{`${i18n._(
              'count',
            )}:${item?.quantity}`}</span>
            <p className='p-0.5 rounded-sm bg-ordme-red text-white flex gap-1 items-center'>
              <IconGift />
              <span>
                <Trans id='gift' />
              </span>
            </p>
          </div>
        ) : (
          <>
            <div className='absolute top-0 left-0'>
              <RemoveItemFromCart
                sx={{
                  '&>.MuiButtonBase-root': {
                    padding: 0,
                    margin: 0,
                    minWidth: 'unset',
                    width: '18px',
                  },
                }}
                {...item}
              >
                <ClearIcon className='text-lg text-ordme-gray-200' />
              </RemoveItemFromCart>
            </div>
            <div className='flex flex-col'>
              <span className='flex text-xs text-[#666]'>{`${i18n._(
                'count',
              )}:${item?.quantity}`}</span>
              {item?.product?.price_range?.minimum_price?.discount?.amount_off ? (
                <div className='bg-ordme-red px-1 font-bold text-white text-[10px]'>تخفیف</div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  ))

  return (
    <WaitForQueries
      waitFor={cart}
      fallback={
        <FullPageMessage icon={<CircularProgress />} title={<Trans id='Loading' />}>
          <Trans id='This may take a second' />
        </FullPageMessage>
      }
    >
      <div className='p-2 flex flex-col gap-6 '>
        {hasItems ? (
          <div>
            {productsElement.map((element) => element)}
            <ApolloCartErrorAlert error={error} />
            <div className='flex max-992:flex-col 992:flex-row text-sm text-light-400 992:items-center 992:justify-between mt-[45px] mb-3'>
              <p className='text-nowrap'>
                <Trans id='Total' />:
                <span className='text-secondary-400 font-semibold *:inline-block'>
                  <Money {...data.cart?.prices?.subtotal_including_tax} />
                </span>
              </p>
              <Link href='/checkout/cart' className='self-end'>
                <button
                  className='ordme_btn ordme_btn_success'
                  onClick={useModal.close}
                >
                  <Trans id='View shopping cart and payment' />
                </button>
              </Link>
              {/* <CartStartCheckout {...data?.cart} /> */}
            </div>
            <p className='text-primary-500 text-xs font-medium flex justify-center'>
              <Trans
                id='Finalize your payment to receive {count} bin discounts!'
                values={{
                  count: earnPoint() ?? 0,
                }}
              />
            </p>
          </div>
        ) : (
          <p className='text-sm text-slate-600'>کالایی به سبد خرید شما اضافه نشده است.</p>
        )}
      </div>
    </WaitForQueries>
  )
}

const pageOptions: PageOptions<LayoutOverlayProps> = {
  overlayGroup: 'cart',
  layoutProps: { variantMd: 'bottom', variantSm: 'bottom' },
}
CartPage.pageOptions = pageOptions

// eslint-disable-next-line import/no-default-export
export default CartPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const conf = client.query({ query: StoreConfigDocument })

  return {
    props: {
      apolloState: await conf.then(() => client.cache.extract()),
    },
  }
}
